import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { FormType } from "../../types/association";
import { ControlPointFile, GetControlPoint, GetOneControlPoint } from "../../types/control-point";

class ControlPointRepository {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getAll(queryParams = {}): Promise<AxiosResponse<GetControlPoint[]>> {
    return this.axiosService.get<GetControlPoint[]>(`/api/control-point/`, false, true, {
      params: queryParams,
    });
  }

  // @todo move to a referential-repository.
  getReferential() {
    return this.axiosService.get("/api/referential/", true, false);
  }

  get(controlPointId: string): Promise<AxiosResponse<GetOneControlPoint>> {
    return this.axiosService.get<GetOneControlPoint>(`/api/control-point/${controlPointId}`, false, true);
  }

  edit(controlPointId: string, values: any) {
    return this.axiosService.put(`/api/control-point/${controlPointId}`, values);
  }

  getFiles(controlPointId: string): Promise<AxiosResponse<ControlPointFile[]>> {
    return this.axiosService.get(`/api/control-point/${controlPointId}/file`, false, true);
  }

  downloadFile(controlPointId: string, fileId: string) {
    return this.axiosService.get(`/api/control-point/${controlPointId}/file/${fileId}/download`, false, true, {
      responseType: "blob",
    });
  }

  // @todo update when back is done.
  async uploadDocument(controlPointId: string, file: File, onProgress?: (percent: number) => void) {
    const formData = new FormData();
    formData.append("attachment", file);
    return this.axiosService.post(`/api/control-point/${controlPointId}/file`, formData, {
      onUploadProgress: (e) => {
        const totalLength = e.lengthComputable
          ? e.total
          : e.target.getResponseHeader("content-length") || e.target.getResponseHeader("x-decompressed-content-length");
        onProgress?.(Math.round((e.loaded * 100) / totalLength));
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      params: {
        filename: file.name,
      },
    });
  }
  
  getForms = (): Promise<AxiosResponse<FormType[]>> => {
    return this.axiosService.get<FormType[]>("/api/form/", false, true);
  };
}

export default new ControlPointRepository(instanceRequest);
