import React, { useCallback, useEffect, useState } from "react";
import { SzPagination, SzTable } from "@suezenv/react-theme-components";
import { getColumns } from "./services/columns";
import ControlPointRepositoryInstance from "../../../../../services/api/repository/control-point-repository";
import { useTranslation } from "react-i18next";
import { useReferentials } from "../../../../../state-management/referential-data/referential-data.hook";
import { prepareFiltersQueryParams } from "../../utils/helper";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";
import { ControlPointFilterType } from "../filter/service/control-point-filter.type";
import { SortType } from "../../../../../services/types/common";
import { GetControlPoint } from "../../../../../services/types/control-point";
import "./ControlPointTable.scss";

interface Props {
    filter: ControlPointFilterType;
    setParentPagination?: ({}) => void;
    setParentSelectedRow?: any;
    setParentSelectedRows?: any;
}

const defaultPagination = {
    "x-pagination-total-count": 0,
    "x-pagination-current-page": 1,
    "x-pagination-page-count": 1,
    "x-pagination-per-page": 20,
};

const Table: React.FC<Props> = ({ filter, setParentPagination, setParentSelectedRow, setParentSelectedRows }) => {
    const [controlPoints, setControlPoints] = useState<any>([]); // @TODO change type
    const [sort, setSort] = useState<SortType>({fields: [], order: []});
    const [pagination, setPagination] = useState<any>(defaultPagination);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedRow, setSelectedRow] = useState<string|null>(null);
    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
    const { t } = useTranslation();
    const { getOption } = useReferentials();
    const onSortData = useCallback((sort: SortType) => {
            setSort(sort);
            getControlPoint(filter, 1, sort);
        },
        [filter]
    );
    
    const rowCheckboxClickHandler = (controlPointId: string) => {
        setControlPoints([]);
        setSelectedRows((prevSelectedRows) => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (updatedSelectedRows.has(controlPointId)) {
                updatedSelectedRows.delete(controlPointId);
            } else {
                updatedSelectedRows.add(controlPointId);
            }
            return updatedSelectedRows;
        });
    };

    const headerCheckboxClickHandler = (e: any) => {
        setControlPoints([]);
        if (e.target.checked) {
            const allRowIds = controlPoints.map((row: any) => row.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    }

    const rowRadioClickHandler = (controlPointId: string) => {
        setControlPoints([]);
        setSelectedRow(controlPointId);
    };

    const columns = getColumns(t, onSortData, getOption);
    
    if(setParentSelectedRow) {
        // Adding a radio column
        columns.unshift({
            dataField: "selectRowRadio",
            text: "",
            formatter: (value, controlPoint) => (
                <label className="radio-container">
                    <input
                        key={controlPoint.id}
                        type="radio"
                        checked={selectedRow === controlPoint.id}
                        onChange={() => rowRadioClickHandler(controlPoint.id)}
                    />
                    <span className="checkmark"></span>
                </label>
            ),
        });
    }
    else if(setParentSelectedRows) {
        // Adding a checkbox column
        columns.unshift({
            dataField: "selectRowCheckbox",
            text: "Select",
            formatter: (value, controlPoint) => (
                <label className="checkbox-container">
                    <input
                        key={controlPoint.id}
                        type="checkbox"
                        checked={selectedRows.has(controlPoint.id)}
                        onChange={() => rowCheckboxClickHandler(controlPoint.id)}
                    />
                    <span className="checkmark"></span>
                </label>
            ),
            headerClasses: "sticky-header",
            headerFormatter: () => (
                <label className="checkbox-container">
                    <input
                        type="checkbox"
                        checked={controlPoints.length > 0 && selectedRows.size === controlPoints.length}
                        onChange={(e) => headerCheckboxClickHandler(e)}
                    />
                    <span className="checkmark"></span>
                </label>
            ),
        });
    }
    else {
        // Adding a empty column
        columns.unshift({
            dataField: "empty",
            text: "",
            headerClasses: "sticky-header",
        });
    }

    const getControlPoint = (filter: ControlPointFilterType, currentPage: number, sort?: SortType) => {
        const queryParams = filter ? { filter: prepareFiltersQueryParams(cloneDeep(filter)) } : {};
        ControlPointRepositoryInstance.getAll({ ...queryParams, currentPage: currentPage, sort })
        .then((controlPoints: AxiosResponse<GetControlPoint[]>) => {
            setControlPoints([...controlPoints.data]);
            setPagination(controlPoints.headers);

            // pass pagination to parent component if required
            setParentPagination && setParentPagination(controlPoints.headers);
        })
        .catch(() => {
            setControlPoints([]);
        });
    };

    useEffect(() => {
        getControlPoint(filter, 1);

        // reset select rows
        setSelectedRow(null);
        setSelectedRows(new Set());
    }, [filter]);

    useEffect(() => {
        getControlPoint(filter, currentPage);
    }, [selectedRow, selectedRows]);

    useEffect(() => {
        setParentSelectedRow && setParentSelectedRow(selectedRow);
    }, [selectedRow]);

    useEffect(() => {
        setParentSelectedRows && setParentSelectedRows(selectedRows);
    }, [selectedRows]);

    return (
        <div className="pdc-table-wrapper">
            {<SzTable keyField="id" className={"pdc-table"} data={controlPoints} columns={columns} />}
            {
                <div className={"text-center d-flex justify-content-center mt-2"}>
                    <SzPagination
                        totalItemsCount={pagination["x-pagination-total-count"]}
                        activePage={pagination["x-pagination-current-page"]}
                        onChange={(page) => {
                            setCurrentPage(page as number);
                            getControlPoint(filter, page as number, sort);
                        }}
                        itemsCountPerPage={pagination["x-pagination-per-page"]}
                        pageRangeDisplayed={3}
                    />
                </div>
            }
        </div>
    );
};

export default Table;
