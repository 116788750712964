import React, { useState } from "react";
import Filter from "../../../components/app/controlPoint/list/filter/filter";
import Table from "../../../components/app/controlPoint/list/table/table";
import { ControlPointFilterType } from "../../../components/app/controlPoint/list/filter/service/control-point-filter.type";
import Export from "../../../components/app/controlPoint/list/export/export";

const defaultFilter: ControlPointFilterType = {
    dateDernierEtatControle: {
        start: null,
        end: null,
    },
    donneeAdmin: {
        contrat: [],
    },
};

const ControlPointList: React.FC = () => {

    const [filter, setFilter] = useState<ControlPointFilterType>(defaultFilter);
    const [pagination, setPagination] = useState<object>({});

    return (
        <>
            <Filter defaultFilter={defaultFilter} setFilter={setFilter}/>
            <Export filter={filter} pagination={pagination}/>
            <Table filter={filter} setParentPagination={setPagination}/>
        </>
    );
}

export default ControlPointList;
