import dayjs from "dayjs";
import { ControlPointFilterType } from "../list/filter/service/control-point-filter.type";
import { omit, isEmpty } from "underscore";

export const prepareFiltersQueryParams = (filters: ControlPointFilterType): any => {
    const mappedQuery: any = filters;
    if (filters) {
        if (mappedQuery.dateDernierEtatControle) {
        mappedQuery.dateDernierEtatControle.start = dateToString(mappedQuery.dateDernierEtatControle?.start!);
        if (!mappedQuery.dateDernierEtatControle.start) {
            delete mappedQuery.dateDernierEtatControle.start;
        }
        mappedQuery.dateDernierEtatControle.end = dateToString(mappedQuery.dateDernierEtatControle?.end!);
        if (!mappedQuery.dateDernierEtatControle.end) {
            delete mappedQuery.dateDernierEtatControle.end;
        }
        }

        return omit(mappedQuery, isEmpty);
    }

    return {};
};

const dateToString = (date?: Date) => {
    if (!date) {
        return;
    }

    return dayjs(date).format("YYYY-MM-DDTHH:mm:s");
};

export const dateFormatter = (date: string|Date, format = "DD/MM/YYYY") => {
    if (!date || !isValidDate(date)) {
        return "";
    }

    return dayjs(date).format(format);
};

export const isValidDate = (date: any) => {
    return dayjs(date).isValid();
};
