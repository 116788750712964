import React, { useEffect, useState } from "react";
import Filter from "../../components/app/controlPoint/list/filter/filter";
import Table from "../../components/app/controlPoint/list/table/table";
import { ControlPointFilterType } from "../../components/app/controlPoint/list/filter/service/control-point-filter.type";
import Export from "../../components/app/controlPoint/list/export/export";
import surveyRepository from "../../services/api/repository/survey-repository";
import { SzButton, SzCheckbox } from "@suezenv/react-theme-components";
import AlertBox, { AlertBoxType } from "../../components/app/alert/AlertBox";
import { useExtractResponseMessage } from "../../services/utils/utilities";
import { useTranslation } from "react-i18next";
import "./PdcAttachment.scss";

const defaultFilter: ControlPointFilterType = {
    dateDernierEtatControle: {
        start: null,
        end: null,
    },
    donneeAdmin: {
        contrat: [],
    },
};

const PdcAttachment: React.FC = () => {
    
    const { t } = useTranslation("controPoint");
    const extractResponseMessage = useExtractResponseMessage();

    const [sourceFilter, setSourceFilter] = useState<ControlPointFilterType>(defaultFilter);
    const [targetFilter, setTargetFilter] = useState<ControlPointFilterType>(defaultFilter);
    const [sourcePagination, setSourcePagination] = useState<object>({});
    const [targetPagination, setTargetPagination] = useState<object>({});
    const [sourcePdc, setSourcePdc] = useState<string|null>(null);
    const [targetPdc, setTargetPdc] = useState<Set<string>>(new Set());
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const [closedSurveyReference, setClosedSurveyReference] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertBoxType>({});

    const onClickSendHandler = async () =>  {
        if(!sourcePdc || targetPdc.size == 0) {
            return;
        }

        const body = {
            closedSurveyReference,
            sourcePdc,
            targetPdc: Array.from(targetPdc)
        }

        surveyRepository
            .attachPdc(body)
            .then(response => {
                setAlert({ type: 'success', message: extractResponseMessage(response) })
            })
            .catch(error => {
                setAlert({ type: 'danger', message: extractResponseMessage(error) })
            });
    }

    useEffect(() => {
        setButtonDisable(false);
        if(!sourcePdc || targetPdc.size == 0) {
            setButtonDisable(true);
        }
    }, [sourcePdc, targetPdc])

    return (
        <>
            <SzCheckbox
                className="closed-survey-reference"
                id="radio-association"
                name={`association`}
                label={t("controlPoint:attachmentPdc.closedSurveyReference")}
                type="checkbox"
                checked={closedSurveyReference}
                onChange={(e) => {setClosedSurveyReference(!closedSurveyReference)}}
            />

            <div className="pdc-attachment-container">
                <div>
                    <h1>{t("controlPoint:attachmentPdc.PdcToAttach")}</h1>
                    <Filter defaultFilter={defaultFilter} setFilter={setSourceFilter}/>
                    <Export filter={sourceFilter} pagination={sourcePagination}/>
                    <Table filter={sourceFilter} setParentPagination={setSourcePagination} setParentSelectedRow={setSourcePdc}/>
                </div>
                <div>
                    <h1>{t("controlPoint:attachmentPdc.PdcToBeAttached")}</h1>
                    <Filter defaultFilter={defaultFilter} setFilter={setTargetFilter}/>
                    <Export filter={targetFilter} pagination={targetPagination}/>
                    <Table filter={targetFilter} setParentPagination={setTargetPagination} setParentSelectedRows={setTargetPdc}/>
                </div>
            </div>
            { 
                alert && 
                <div className="row pt-2 mx-auto">
                    <AlertBox {...alert} />
                </div>
            }
            <div className="row justify-content-end pt-1">
                <div className="col-auto">
                    <SzButton variant="secondary" onClick={onClickSendHandler} isDisabled={buttonDisable} className="send-button">
                        {t("controlPoint:attachmentPdc.send")}
                    </SzButton>
                </div>
            </div>
        </>
    );
}

export default PdcAttachment;