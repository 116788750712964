import React from "react";
import { SzAlert } from "@suezenv/react-theme-components";
import "./AlertBox.scss"

export interface AlertBoxType {
    type?: 'success' | 'danger' | 'warning' | 'info';
    message?: string|null;
    className?: string|null;
}

const AlertBox: React.FC<AlertBoxType> = ({ type = 'danger', message = null, className = null }) => {

    const classes = ['alert-box'];
    if(className) {
        classes.push(className)
    }

    if(!message) {
        return null;
    }

    return (
        <SzAlert className={classes.join(' ')} variant={type}>
            {message}
        </SzAlert>
    );
};

export default AlertBox;
