import { GetSurvey, Status, SurveyData, SurveyRequest, SurveyStatusHistory, FileData } from "../../types/survey";
import instanceRequest, { Request } from "../../Request";
import { AxiosResponse } from "axios";
import { SortType } from "../../types/common";
import { PdcAttachmentType } from "../../types/control-point";

export class SurveyRepository {
  private axiosService: Request;

  /**
   * @param axiosService
   */
  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getSurveys(filter: any = {}, pagination?: any, sort?: SortType): Promise<AxiosResponse<GetSurvey[]>> {
    return this.axiosService.get<GetSurvey[]>(`/api/operation`, false, true, {
      params: {
        ...pagination,
        ...filter,
        sort,
      },
    });
  }

  updateSurveyData(surveyId: string, body: SurveyData) {
    return this.axiosService.put(`/api/operation/${surveyId}/data`, body);
  }
  getSurvey(id: string): Promise<AxiosResponse<SurveyRequest>> {
    return this.axiosService.get<SurveyRequest>(`/api/operation/${id}`, false, true);
  }

  getFormData(id: string): Promise<AxiosResponse<SurveyData>> {
    return this.axiosService.get<SurveyData>(`/api/operation/${id}/data`, false, true);
  }
  getFormSchema(id: string): Promise<AxiosResponse<any>> {
    return this.axiosService.get<any>(`/api/operation/${id}/form`, false, true);
  }

  getStatusHistory(id: string): Promise<AxiosResponse<SurveyStatusHistory[]>> {
    return this.axiosService.get<SurveyStatusHistory[]>(`/api/operation/${id}/status`, false, true);
  }

  updateStatus(operationId: string, status: Status) {
    return this.axiosService.post(`/api/operation/${operationId}/status`, {
      status: status,
      comment: "",
    });
  }

  // upload Document.
  async uploadDocument(surveyId: string, fileUuid: string, file: File, target: string) {
    const formData = new FormData();
    formData.append("attachment", file);
    return this.axiosService.post(`/api/operation/${surveyId}/file/${fileUuid}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      params: {
        filename: file.name ? file.name : `file-${Date.now()}`,
        target: target,
      },
    });
  }

  async getDocument(surveyId: string, fileUuid: string) {
    return this.axiosService.get(`/api/operation/${surveyId}/file/${fileUuid}`, false, true, {
      responseType: "blob",
    });
  }

  getFiles(surveyId: string): Promise<AxiosResponse<FileData[]>> {
    return this.axiosService.get<FileData[]>(`/api/operation/${surveyId}/file`, false, true);
  }

  isFileExist(surveyId: string, fileId: string) {
    return this.axiosService.head(`/api/operation/${surveyId}/file/${fileId}`);
  }
  
  attachPdc = (body: PdcAttachmentType) => {
    return this.axiosService.post("/api/operation/pdc-attachment", body);
  };
}

export default new SurveyRepository(instanceRequest);
