import React from "react";
import { SzButton } from "@suezenv/react-theme-components";
import { cloneDeep } from "lodash";
import ControlPointRepositoryInstance from "../../../../../services/api/repository/control-point-repository";
import { dateFormatter, prepareFiltersQueryParams } from "../../utils/helper";
import { AxiosResponse } from "axios";
import { ControlPointFilterType } from "../filter/service/control-point-filter.type";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../state-management";
import { ADMIN_SERVICE } from "../../../../../services/utils/accessManagment";
import { useTranslation } from "react-i18next";

interface Props {
    filter: ControlPointFilterType;
    pagination: any
}

const Export: React.FC<Props> = ({filter, pagination}) => {
    const { t } = useTranslation();

    const userSelect = useSelector((state: RootState) => state.userReducer);
    const isAdmin = userSelect.data!.roles.includes(`ROLE_${ADMIN_SERVICE}`);

    const exportCsv = () => {
        const windowUrl = window.URL;
        const now = new Date();
        const link = document.createElement("a");
        link.setAttribute("download", `pdc_${dateFormatter(now, "YYYYMMDDTHHmms")}.json`);

        const queryParams = filter
        ? { filter: prepareFiltersQueryParams(cloneDeep(filter)) }
        : {};

        ControlPointRepositoryInstance.getAll({
            ...queryParams,
            currentPage: 1,
            itemsPerPage: pagination["x-pagination-total-count"],
        }).then((controlPoints: AxiosResponse<any>) => {
            const url = windowUrl.createObjectURL(new Blob([JSON.stringify(controlPoints.data)]));
            link.href = url;
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <div className={"action-buttons d-flex justify-content-end"}>
            <SzButton onClick={exportCsv} isDisabled={!isAdmin} variant="tertiary" icon="download-bottom" alignIcon="right">
                {t("controlPoint:filter.exportCsv")}
            </SzButton>
        </div>
    );
}

export default Export;