import React from "react";
import {SzIcon, SzSideMenu} from "@suezenv/react-theme-components";

interface Props {
    isActive: boolean;
    onClick: () => void;
    icon: string;
    title: string;
}

const NavigationItem:React.FC<Props> = React.memo(({isActive, onClick, icon, title}) => {

    let iconElement: any = icon;
    if (typeof icon === 'string') {
        iconElement = <SzIcon icon={icon} variant="line" />
    }

    return (
        <SzSideMenu.Item active={isActive} onClick={onClick}>
            {iconElement}
            <span className={'sz-aside__item--title'}>{title}</span>
        </SzSideMenu.Item>
    );
}, (prev, next) => prev.isActive === next.isActive);

export default NavigationItem;
