export const useExtractResponseMessage = () => {

    return (resp: any) => {
        
        let response = resp;
        if('response' in resp) {
            response = resp.response;
        }

        let message;
        if (typeof response.data === 'string' || response.data instanceof String) {
            message = response.data;
        }
        else if (typeof response.data === "object") {
            if ("errors" in response.data) {
                message = response.data.errors;
            } else if ("message" in response.data) {
                message = response.data.message;
            }
        }

        if(response.status >= 400) {
            console.error("error", { ...response });
        }

        return message;
    };
};